import request from 'utils/request';

const api = {
  SUBRULE_LIST: '/api/subrule/query',
  SUBRULE_REPORT_LIST: '/api/offer/querySub',
  SUBRULE_STATUS_UPDATE: '/api/subrule/status',
  SUBRULE_STATUS_DETAILS: '/api/subrule/query/',
  SUBRULE_OPTION: '/api/subrule/query-option',
  SUBRULE_UPDATE: '/api/subrule/update',
  SUBRULE_ADD: '/api/subrule/insert',
  SUBRULE_DELETE: '/api/subrule/delete/',
  SUBRULE_LOG_LIST: '/api/offer/selectLogSubAff',
  SUBRULE_COUNT_LIST: '/api/offer/selectSubAff',
  SUBRULE_REJECTION_DELETE: '/api/subrule/delete/rejection/',
  SUBRULE_ANALYSIS_DELETE: '/api/subrule/delete/analysis/',
  SUBRULE_PAGE_LIST: '/api/subrule/selectAll',
};

/**
 * 查询Subrule列表
 * @param {*} query {smartLinkIds:Array<int>,status:string,page:int,pageSize:int}
 */
export function getSubruleList(query) {
  return request({
    url: api.SUBRULE_LIST,
    method: 'get',
    params: query,
  });
}

export function getSubRulePage(query) {
  return request({
    url: api.SUBRULE_PAGE_LIST,
    method: 'get',
    params: query,
  });
}

/**
 * 查询report Subrule列表
 * @param {*} query {smartLinkIds:Array<int>,status:string,page:int,pageSize:int}
 */
export function getReportSubruleList(query) {
  return request({
    url: api.SUBRULE_REPORT_LIST,
    method: 'get',
    params: query,
  });
}

/**
 * 修改Subrule状态
 * @param {object} data {id:int,clickCap:int,priority:int}
 */
export function updateSubruleStatus(data) {
  return request({
    url: api.SUBRULE_STATUS_UPDATE,
    method: 'put',
    data,
  });
}

/**
 * 查询Subrule详情
 * @param {*} query {smartLinkIds:Array<int>,status:string,page:int,pageSize:int}
 */
export function getSubruleDetails(id) {
  return request({
    url: api.SUBRULE_STATUS_DETAILS + id,
    method: 'get',
  });
}

/**
 * 查询Subrule下拉
 * @param {*} query {smartLinkIds:Array<int>,status:string,page:int,pageSize:int}
 */
export function getSubruleOption() {
  return request({
    url: api.SUBRULE_OPTION,
    method: 'get',
  });
}

/**
 * 修改Subrule
 * @param {object} data {id:int,clickCap:int,priority:int}
 */
export function updateSubrule(data) {
  return request({
    url: api.SUBRULE_UPDATE,
    method: 'put',
    data,
  });
}

/**
 * 新增Subrule
 * @param {*} data {smartLinkId:int,offerId:int,clickCap:int,priority:int}
 */
export function addSubrule(data) {
  return request({
    url: api.SUBRULE_ADD,
    method: 'post',
    data,
  });
}

/**
 * 查询sub_log
 * @param {*} query {smartLinkIds:Array<int>,status:string,page:int,pageSize:int}
 */
export function getSubruleLogList(query) {
  return request({
    url: api.SUBRULE_LOG_LIST,
    method: 'get',
    params: query,
  });
}

/**
 * 查询sub_count
 * @param {*} query {smartLinkIds:Array<int>,status:string,page:int,pageSize:int}
 */
export function getSubruleCountList(query) {
  return request({
    url: api.SUBRULE_COUNT_LIST,
    method: 'get',
    params: query,
  });
}

/**
 * 删除
 * @param {*}
 */
export function deleteSmartLink(id) {
  return request({
    url: api.SUBRULE_DELETE + id,
    method: 'delete',
  });
}

/**
 * 删除rejectionPostback
 * @param {*}
 */
export function deleteRejectionPostback(id) {
  return request({
    url: api.SUBRULE_REJECTION_DELETE + id,
    method: 'delete',
  });
}

/**
 * 删除rejectionPostback
 * @param {*}
 */
export function deleteReportAnaylsis(id) {
  return request({
    url: api.SUBRULE_ANALYSIS_DELETE + id,
    method: 'delete',
  });
}
