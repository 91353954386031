<template>
  <div class="productOverall">
    <!-- <div class="main-Title bgff"><h2>Sub Rule List</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="7" :md="7" :lg="7" :xl="7">
            <el-form :inline="true" :model="filterForm" size="small">
              <el-form-item class="mb5" label="ruleName:" label-width="25%">
                <el-input
                  clearable
                  v-model="filterForm.ruleName"
                  placeholder="Please enter ruleName"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
            <el-form :inline="true" :model="filterForm" size="small">
              <el-form-item class="mb5">
                <el-button type="primary" @click="getList(1)">Search</el-button>
                <el-button type="primary" @click="add()">Create</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
      <el-card shadow="hover">
        <el-table :data="list" border stripe class="w100" size="mini" v-loading="loading.table">
          <el-table-column label="ruleId" prop="ruleId"></el-table-column>
          <el-table-column label="ruleName" prop="ruleName" min-width="100"></el-table-column>
          <el-table-column label="createTime" prop="createTime"></el-table-column>
          <el-table-column label="updateTime" prop="updateTime"></el-table-column>
          <el-table-column label="offerCount" prop="offerCount"></el-table-column>
          <el-table-column label="status" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">on</span>
              <span v-else>Off</span>
            </template>
          </el-table-column>
          <el-table-column label="operate" align="center" width="250">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                class="mr10"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="statusChange(scope.row.status, scope.row.ruleId, scope.$index)"
              ></el-switch>
              <el-button type="primary" size="mini" @click="toEdit(scope.row)">Edit</el-button>
              <el-button type="danger" size="mini" @click="deletes(scope.row)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import subruleCon from '../controllers/product/subrule';

  export default {
    name: 'subrule',
    components: {},
    ...subruleCon,
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
