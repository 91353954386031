import { updateSubruleStatus, deleteSmartLink, getSubRulePage } from 'api/product/subrule.js';
import Pagination from '@/components/pagination';

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      filter: {},
      filterForm: {},
      list: [],
      loading: {
        table: true,
        total: 0,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(page) {
      if (page) {
        this.filterForm.page = page;
      }
      this.loading.table = true;
      getSubRulePage(this.filterForm)
        .then((res) => {
          if (res.code === 200) {
            this.list = res.result;
            this.total = res.total;
            this.loading.table = false;
          } else {
            this.loading.table = false;
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
          this.loading.table = false;
        });
    }, // 更改状态
    statusChange(status, id, index) {
      updateSubruleStatus({ ruleId: id, status: status }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: '状态更新成功！',
            type: 'success',
          });
        } else {
          this.list[index].status = !status;
          this.$message.error(res.message);
        }
      });
    }, // 跳转编辑
    toEdit(row) {
      this.$router.push({
        path: '/offer/subrule/edit',
        query: {
          id: row.ruleId,
        },
      });
    }, // 删除操作
    deletes(row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          deleteSmartLink(row.ruleId).then((res) => {
            if (res.code !== 200 && res.success == false) {
              this.$message.error(res.message);
            } else {
              this.getList();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    }, // 跳转添加
    add() {
      this.$router.push({
        path: '/offer/subrule/add',
      });
    },
    handleSizeChange(size) {
      this.filterForm.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.filterForm.page = currentPage;
      this.getList();
    },
  },
};
